<template>
	<div class="box">
		<div id="top-box">
			<img src="../../assets/holt.jpg" alt="">
		</div>
		<van-calendar v-model:show="show" type="range" @confirm="onConfirm" color="#FC6228" />
		<div id="centre-box">
			<div id="nameBox">
				<div id="nameBox1">
					<p class="name1">{{nameBox.name}}</p>
					<div class="scoreInfo">
						<p class="score">{{nameBox.score}}分</p>
						<p class="info" v-for="(item,index) in nameBox.info">
							{{item.name}}
						</p>
					</div>
					<p class="address">{{nameBox.address}}</p>
				</div>
			</div>
			
			<div id="conBox">
				<div class="calendar">
					<div class="calendar-left">
						<span>{{date.value}}</span>
					</div>
					<div class="calendar-right" @click="showCalendar ">
					<span v-if="dayNum">共{{dayNum}}晚></span>
					<span v-else>共1晚></span>
					</div>
				</div>
				<div class="roomList">
					<div class="roomTitle">
						<p v-for="(item,index) in room.title">{{item.name}}</p>
					</div>
				</div>
				<!--  -->
				<div v-for="(item,index) in room.list" class="room-item">
				
						<img src="../../assets/holt.jpg" alt="" style="width: 77px;height: 77px;border-radius: 5px;">
					
					<div style="font-size: 0.2rem;" class="infoTitle">
						<div class="infoTitle1">
							<p style="font-size: 14px;font-weight: bold;">{{item.name}}</p>
							<p
								style="font-size: 11px;color: #666666;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
								{{item.details}}
							</p>
							<p style="font-size: 17px;color: #FC6228;font-weight: bold;"><span style="font-size: 12px;">￥</span><span>{{item.price}}</span></p>
						</div>
						<div style="font-size: 0.2rem;" class="drawUp" @click="hoteSubmit()">
							<p class="draw1">订</p>
							<p class="onlineF">在线付</p>
						</div>
					</div>
				</div>
				<!--  -->
				<div class="evaluate">
					<h3>网友评价</h3>
					<div class="grade">
						<div class="leftGrade">
							<p style="font-size: 0.5rem;color: #FC6228;">4.5</p>
							<div style="color: #1A1A1A;font-weight: bold;font-size: 12px;">
								<p>推荐</p>
								<p>85条评论</p>
							</div>
						</div>
						<div class="rightGrade">
							<div class="list1">
								<p>位置 4.9</p>
									<p style="width: 50px;height: 3px;background: #FC6228;border: 2px solid #FC6228;
border-radius: 3px;"></p>
							</div>
							<div class="list1">
								<p>服务  4.9</p>
									<p style="width: 50px;height: 3px;background: #FC6228;border: 2px solid #FC6228;
							border-radius: 3px;"></p>
							</div>
						</div>
					</div>
					<div class="evaluateList">
						<div class="evaluateTitle">
							<p v-for="(item,index) in evaluate.title">{{item.name}}</p>
						</div>
					</div>
					<div v-for="(item,index) in evaluate.peoList" class="characters">
						<div class="topHeader">
							<div class="topHeader1">
								<img :src="item.portrait" alt="" style="width: 40px;height: 40px;border-radius: 50%;">
								<div class="topHeader11">
									<p>{{item.name}}</p>
									<van-rate v-model="item.star" allow-half color="#FC6228" :size="15"/>
								</div>
							</div>
							<div class="topHeader2">{{item.time}}入住</div>
						</div>
						<div class="detail">
							{{item.detail}}
						</div>
						<div class="imageList">
							<img :src="item.portrait" alt="" v-for="(item,index) in item.image">
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		ref,reactive 
	} from 'vue';
	export default {
		  setup() {
		    // const date = ref('');
			const dayNum = ref('');
		    const show = ref(false);
		
		    const formatDate = (date) => `${date.getMonth() + 1}月${date.getDate()}日`;
			const date = reactive({
			      value:formatDate(new Date()) 
			    })
			//日期格式化
			const dateFormat = (fmt, date)=> {
				console.log(date)
			    let ret;
			    const opt = {
			        "Y+": date.getFullYear().toString(),        // 年
			        "m+": (date.getMonth() + 1).toString(),     // 月
			        "d+": date.getDate().toString(),            // 日
			        "H+": date.getHours().toString(),           // 时
			        "M+": date.getMinutes().toString(),         // 分
			        "S+": date.getSeconds().toString()          // 秒
			        // 有其他格式化字符需求可以继续添加，必须转化成字符串
			    };
			    for (let k in opt) {
			        ret = new RegExp("(" + k + ")").exec(fmt);
			        if (ret) {
			            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
			        };
			    };
			    return fmt;
			};
			//日期相差
			const DateDiff = (sDate1, sDate2)=> { //sDate1和sDate2是2017-9-25格式 
			    var aDate, oDate1, oDate2, iDays
			    aDate = sDate1.split("-")
			    oDate1 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0]) //转换为9-25-2017格式 
			    aDate = sDate2.split("-")
			    oDate2 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0])
			    iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24) //把相差的毫秒数转换为天数 
			    return iDays
			    };
		    const onConfirm = (values) => {
				
		      const [start, end] = values;
			 dayNum.value =  DateDiff(dateFormat('YYYY-mm-dd',start),dateFormat('YYYY-mm-dd',end))
			
			console.log(dayNum)
		      show.value = false;
		      date.value = `${formatDate(start)} - ${formatDate(end)}`;
		    };
		
		    return {
		      date,
		      show,
		      onConfirm,
			  dayNum
		    };
		  },
		data() {
			return {
				nameBox: {
					name: "郑州JW万豪酒店",
					score: 4.5,
					address: "商务内环路商务中央公园2号千禧广场郑东新区及会展中心区",
					info: [{
							name: "豪华型"
						},
						{
							name: "环境好"
						}
					]
				},
				room: {
					title: [{
							name: "钟点房",
						},
						{
							name: "棋牌房",
						},
						{
							name: "双床房",
						},
						{
							name: "亲子房",
						}
					],
					list: [{
							name: "经济特价大床房",
							details: "无早餐 15-30m² 大床 2人入住 有窗",
							price: 789,
						},
						{
							name: "标间",
							details: "无早餐 15-30m² 大床 2人入住 有窗",
							price: 789,
						},
						{
							name: "经济特价大床房",
							details: "无早餐 15-30m² 大床 2人入住 有窗",
							price: 789,
						}
					]
				},
				evaluate:{
					title: [{
							name: "干净整洁（15）",
						},
						{
							name: "服务好（15）",
						},
						{
							name: "性价比高（15）",
						},
						{
							name: "位置好（15）",
						}
					],
					peoList:[
						{
							name:"肚子又圆了",
							portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
							detail:"不错卫生好，价格便宜。房间宽敞，位置好，厨房大可以做饭，有超大玻璃风景无限好",
							star:3.5,
							time:"2020-03-12",
							image:[
								{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								},
								{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								},
								{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								}
							]
						},
						{
							name:"胡巴胡巴",
							portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
							detail:"不错卫生好，价格便宜。房间宽敞，位置好，厨房大可以做饭，有超大玻璃风景无限好",
							star:4.5,
							time:"2020-05-12",
							image:[
								{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								},
								{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								},
								{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								}
							]
						}
					]
				}
			}
		},
		mounted() {
			console.log(this.data)
		},
		methods: {
			
			showCalendar(){
				this.show = true
				console.log(this.data)
			},
			hoteSubmit(){
				this.$router.push({
					path: '/h5/hoteSumit',
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	p {
		margin: 0;
		padding: 0;
	}

	.box {
		background-color: #FFFFFF;
	}

	#top-box {
		height: 150px;
		background-size: 100% 120%;
		background-position: center center;
		filter: brightness(0.8);
	}

	#top-box img {
		width: 100%;
		height: 100%;
	}
.calendar{
			
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 10px;
			.calendar-left{
				font-size: 15px;
			}
			.calendar-right{
				font-size: 12px;
				color: #FC6228;
			}
		}
	#centre-box {
		// padding: 4vw 0 0;
		position: relative;
		display: flex;
		// flex-direction: column;
		/* align-items: center; */
		// background-color: #fff;
		
	}

	#nameBox {
		width: 100%;
		// height: 120px;
		position: absolute;
		top: -60px;
		display: flex;
		justify-content: center;
		font-size: 0.2rem;
		padding: 15px;
		box-sizing: border-box;
	}

	#nameBox1 {
		width: 100%;
		height: 100%;
		background: #FFFFFF;
		box-shadow: 0px 3px 10px 0px rgba(61, 61, 61, 0.24);
		border-radius: 5px;
		padding: 10px;
	}

	.name1 {
		font-size: 0.22rem;
		font-weight: bold;
	}

	.scoreInfo {
		display: flex;
		margin: 0.15rem 0;
	}

	.score {}

	.info {
		background-color: #EEEEEE;
		padding: 3px 5px;
		margin-left: 5px;
		border-radius: 5px;
		font-size: 12px;
	}

	.address {
		color: #333333;
		margin: 0;
		font-size: 12px;
		margin-top: 10px;
		word-break: break-all;
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	#conBox {
		width: 100%;
		
		margin-top: 80px;
		padding: 10px 15px;
		box-sizing: border-box;
	}

	.roomList {
		font-size: 0.2rem;
	}

	.roomTitle {
		display: flex;
		align-items: center;

		p {
			margin-right: 10px;
			background-color: #F4F5F9;
			padding: 3px 5px;
			font-size: 12px;
		}
	}

	.room-item{
		width: 100%;
		display: flex;
		height: 110px;
		align-items: center;
		// justify-content: space-between;
	}

	.infoTitle {
		width: calc(100% - 87px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 10px;
		// width: 50%;
	}

	.infoTitle1 {
		width: 70%;

		p {
			margin: 8px 0;
		}

		// display: flex;
	}

	.drawUp {
		width: 42px;
		height: 45px;
		background: #FFFFFF;
		// border: 2px solid #FC6228;
		// border-radius: 8px;
		text-align: center;
	}

	.draw1 {
		width: 42px;
		height: 28px;
		line-height: 28px;
		background: #FC6228;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		color: #FFFFFF;
	}

	.onlineF {
		width: 42px;
		height: 17px;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
		border: 1px solid #FC6228;
		font-size: 10px;
		box-sizing: border-box;
		color: #FC6228;
	}

	.evaluate {
		h3 {
			font-size: 0.23rem;
		}

		.grade {
			font-size: 0.2rem;
			display: flex;
align-items: center;
			.leftGrade {
				display: flex;
				align-items: center;
			}

			.rightGrade {
				margin-left: 10px;
				font-size: 12px;
				.list1 {
					display: flex;
					align-items: center;
				}
			}
		}
		// 
		.evaluateList{
			font-size: 0.2rem;
			.evaluateTitle{
				// float: left;
				display: flex;
				 flex-wrap:wrap;
				justify-content: space-between;
				margin: 10px 0;
				p {
					background-color: #F4F5F9;
					font-size: 12px;
					width: 110px;
					height:27px;
					line-height: 0.4rem;
					text-align: center;
					// margin-right: 0.1rem;
					margin-bottom: 5px;
					// width: 40%;
				}
			}
		}
		// 
		.characters{
			
			margin-bottom: 18px;
			.topHeader{
				display: flex;
				justify-content: space-between;
				align-items: center;
				.topHeader1{
					display: flex;
					align-items: center;
					img{
						
					}
					.topHeader11{
						margin-left: 8px;
						font-size: 15px;
						font-weight: bold;
					}
				}
				.topHeader2{
					color: #999999;
					font-size: 11px;
				}
			}
			.detail{
				color: #333333;
				margin: 10px 0;
				font-size: 15px;
			}
			.imageList{
				width: 100%;
				// height: 70px;
				display: flex;
				justify-content: space-between;
				flex-wrap:wrap;
				img{
					width: 108px;
					height: 108px;
					border-radius: 5px;
				}
			}
		}
	}
</style>
